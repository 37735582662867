/**
 * @file
 * Custom scripts for theme.
 */
String.prototype.replaceAll = function(search, replacement) {
	var target = this;
	return target.replace(new RegExp(search, 'g'), replacement);
};
(function ($) {
  // Add your code here.
  
    jQuery(document).ready(function(){
  	jQuery('#admin-menu').css('z-index',100000);
  	jQuery('body').css('padding-top',jQuery('#header nav').css('height'));
  	jQuery('body.adminimal-menu div#admin-menu.admin-menu-position-fixed').css('top',jQuery('#header nav').css('height'));
});
  jQuery(window).resize(function(){
  	jQuery('body').css('padding-top',jQuery('#header nav').css('height'));
  	jQuery('body.adminimal-menu div#admin-menu.admin-menu-position-fixed').css('top',jQuery('#header nav').css('height'));
  })
	jQuery(window).bind('commerce_ajax_cart_update',function(e, formId){
	  var id = '.'+formId.replaceAll('_','-');
	  var cantidad = jQuery(id).find('input[name=quantity]').val();
	  console.log(jQuery(id).find('input[name=quantity]'));
	  var precio = jQuery(id).parent().parent().parent().parent().find('.field-type-commerce-price > div > div').text();
	  var producto = jQuery(id).parent().parent().parent().parent().parent().find('h2 a').text();
	  if(producto == '' || producto == undefined){
	  	producto = jQuery('h1.title').text();
	  }
	  jQuery('#cart-quantity').text((parseInt(jQuery('#cart-quantity').text())*1+cantidad*1));
	  jQuery('.modal-title').text(producto);
	  jQuery('.modal-body p.din').html('Agregaste '+cantidad+' <strong>'+producto+'</strong> por un total de <strong>$'+(parseInt(precio)*cantidad)+'</strong>.');
	  jQuery('#add2CartModal').modal('show');
	  var sobre = false;
	  jQuery('#add2CartModal').on('mouseenter', function (){
	  	sobre = true;
	  });
	  jQuery('#add2CartModal').on('mouseleave', function (){
	  	sobre = false;
	  });
	  var hide = setInterval(function(){if(!sobre){jQuery('#add2CartModal').modal('hide'); clearInterval(hide);}},2000);
	})

})(jQuery);
